export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "NOMBRE MENSAJE",
    dataIndex: "denominacion",
    key: "denominacion",
    width: 220,
    ellipsis: true
  },
  {
    title: "ABREVIATURA",
    dataIndex: "abreviatura",
    key: "abreviatura",
    width: 180,
    ellipsis: true
  },
  {
    title: "DESCRIPCIÓN",
    dataIndex: "descripcion",
    key: "descripcion",
    width: 280,
    ellipsis: true
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" },
    width: 120
  }
];
