<template>
  <a-modal
    :visible="true"
    :title="[isEdit ? 'Editar mensaje' : 'Nuevo mensaje']"
    class="max-w-sm md:max-w-lg"
    @close="closeModal"
    @cancel="handleCancel"
    @ok="handleOk"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form layout="vertical" :model="formState" :rules="rules" ref="formRef">
      <a-form-item label="Nombre" name="denominacion" class="w-full">
        <a-input v-model:value="formState.denominacion" />
      </a-form-item>

      <a-form-item :label="mensajemax" name="descripcion" class="w-full">
        <a-textarea :maxlength="maxlength" v-model:value="formState.descripcion" :rows="3" />
      </a-form-item>

      <a-form-item label="Abreviatura" name="abreviatura" class="w-full">
        <a-input v-model:value="formState.abreviatura" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import VigilanciaMensajeApi from "@/api/sms_mensajes";
import { reactive, onMounted, toRaw, ref, computed, watch } from "vue";
import { notification } from "ant-design-vue";
import UserApi from "@/api/user";

export default {
  props: {
    mensaje: {
      type: Object
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  emits: ["closeModal", "fetchMensajes", "clearSelectedArrays"],
  setup(props, { emit }) {
    const formRef = ref();
    const formState = reactive(props.mensaje);
    const isEdit = !!props.mensaje.idmensaje;
    const mensajemax = ref("");
    const maxlength = ref(10);

    const closeModal = () => {
      emit("closeModal");
    };

    const handleCancel = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);

          let response = {};

          if (isEdit) {
            response = await VigilanciaMensajeApi.editOne(payload, payload.idmensaje);

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: "Se ha actualizado satisfactoriamente el mensaje"
              });
            }

            emit("clearSelectedArrays");
            emit("closeModal");
            emit("fetchMensajes", props.currentPage);
          } else {
            response = await VigilanciaMensajeApi.addOne(payload);

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: "Se ha creado satisfactoriamente el mensaje"
              });
            }

            emit("clearSelectedArrays");
            emit("closeModal");
            emit("fetchMensajes", props.currentPage);
          }
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      UserApi.getParametroxDenominacion("TEXTO_MENSAJE")
        .then((res) => {
          maxlength.value = Number(res.data[0].valor);
          mensajemax.value = "Contenido mensaje (máx. " + res.data[0].valor + " carácteres)";
        })
        .catch((err) => console.log(err));
    });

    const rules = {
      denominacion: [
        {
          required: true,
          message: "El campo denominacion no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 60,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ],
      descripcion: [
        {
          required: true,
          message: "El campo descripcion no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 160,
          message: "La cantidad máxima de caracteres es de 160",
          trigger: "blur"
        }
      ],
      abreviatura: [
        {
          max: 20,
          message: "La cantidad máxima de caracteres es de 20",
          trigger: "blur"
        }
      ]
    };

    return {
      closeModal,
      handleOk,
      rules,
      formRef,
      formState,
      handleCancel,
      isEdit,
      maxlength,
      mensajemax
    };
  }
};
</script>
