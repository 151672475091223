<template>
  <!-- <a-page-header @back="goBack" title="DESTINATARIOS SMS " :breadcrumb="{ routes }" class="px-0" /> -->
  <!-- <a-page-header @back="() => $router.go(-1)" title="MENSAJES SMS" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Vigilancia</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Mensajes SMS</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header> -->

  <PageHeader
    title="MENSAJES SMS"
    :level1="{ title: 'Vigilancia', route: '/menu' }"
    :level2="{ title: 'Mensajes SMS', route: '/' }"
    goBack
  />

  <div class="flex flex-col mb-4 md:flex-row">
    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-left ml-2">Filtrar destinatarios</legend>
      <MensajesFiltro @fetchMensajes="fetchMensajes" />
    </fieldset>

    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-center">Acciones</legend>

      <MensajesAcciones
        :mensaje="mensaje"
        :isDisabled="isDisabled"
        @handleAdd="handleAdd"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @handleChangeState="handleChangeState"
      />
    </fieldset>
  </div>

  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="data"
      :custom-row="customRow"
      size="small"
      :pagination="false"
      :loading="isLoading"
      rowKey="idmensaje"
      :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
    >
      <template #rangepages="{ index }">
        <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
      </template>

      <template #activo="{ text: activo }">
        <span>
          <a-tag :color="activo === '1' ? 'green' : 'volcano'">
            {{ activo === "1" ? "Activado" : "Desactivado" }}
          </a-tag>
        </span>
      </template>
    </a-table>
  </div>

  <!-- <footer class="flex flex-col md:flex-row gap-4 items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex">
      <a-pagination v-model:current="currentPage" v-model:pageSize="pageSize" :total="totalDocs" />

      <button class="ml-4" @click="fetchMensajes">
        <ReloadOutlined />
      </button>
    </div>
    <p>Total de registros: {{ totalDocs }}</p>
  </footer> -->

  <FooterTotalDocs
    :currentPage="currentPage"
    :pageSize="pageSize"
    :totalDocs="totalDocs"
    @fetchData="fetchMensajes"
  />

  <MensajesAddEdit
    v-if="isModalVisible"
    :currentPage="currentPage"
    :mensaje="mensaje"
    @closeModal="closeModal"
    @fetchMensajes="fetchMensajes"
    @clearSelectedArrays="clearSelectedArrays"
  />
</template>

<script>
import { ref, watch, onMounted, computed, reactive, toRaw, createVNode } from "vue";
import { columns } from "./utilsMensajes";
import router from "@/router";
import UsuariosApi from "@/api/user";
import MensajesApi from "@/api/sms_mensajes";
import MensajesFiltro from "./MensajesFiltro.vue";
import MensajesAddEdit from "./MensajesAddEdit.vue";
import MensajesAcciones from "./MensajesAcciones.vue";
import PageHeader from "@/components/PageHeader.vue";
import FooterTotalDocs from "@/components/FooterTotalDocs.vue";
import { Modal, notification } from "ant-design-vue";
import { useStore } from "vuex";
import {
  FileAddOutlined,
  FormOutlined,
  IssuesCloseOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CommentOutlined,
  ReloadOutlined
} from "@ant-design/icons-vue";

export default {
  name: "AppMensajes",
  components: {
    MensajesFiltro,
    MensajesAcciones,
    MensajesAddEdit,
    PageHeader,
    FooterTotalDocs,
    // icons
    FileAddOutlined,
    IssuesCloseOutlined,
    CommentOutlined,
    FormOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    ReloadOutlined
  },
  setup() {
    const data = ref([]);
    const store = useStore();
    const mensaje = ref(null);
    const isModalVisible = ref(false);
    const isDisabled = ref(true);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalDocs = ref(null);
    const isLoading = ref(false);
    const state = reactive({
      selectedRowKeys: []
    });

    const { establecimiento } = store.state.user.usuario;
    // const goBack = () => router.back();
    const showModal = () => {
      isModalVisible.value = true;
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };

    const fetchMensajes = (currentPage = 1) => {
      const filter = store.getters["mensajes/filter"];
      const search = store.getters["mensajes/search"];

      isLoading.value = true;
      MensajesApi.getAll(currentPage, filter, search)
        .then((response) => {
          console.log(response);
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      store.dispatch("destinatario/cleanFilters");
      UsuariosApi.userLogAdd("Vigilancia", "Mensajes", establecimiento);
      fetchMensajes();
    });

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
      state.rowActiveStatus = [];
    };

    // -------- para selecionar los datos de la tabla --------
    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          mensaje.value = toRaw(selectedRows[0]);
        }
      };
    });

    // const selectRow = (record) => {
    //   console.log(record);
    //   const selectedRowKeys = [...state.selectedRowKeys];
    //   if (selectedRowKeys.indexOf(record.idusuario) >= 0) {
    //     selectedRowKeys.splice(selectedRowKeys.indexOf(record.idusuario), 1);
    //   } else {
    //     selectedRowKeys.push(record.idusuario);
    //   }
    //   state.selectedRowKeys = selectedRowKeys;
    // };

    // const customRow = (record) => {
    //   return {
    //     onClick: () => {
    //       selectRow(record);
    //     }
    //   };
    // };

    // ------- desactiva la seleccion actual -------
    const customRow = (record) => {
      return {
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    const handleAdd = () => {
      mensaje.value = {
        denominacion: "",
        descripcion: "",
        abreviatura: "",
        idmensaje: ""
      };

      console.log(mensaje);
      showModal();
    };

    const handleChangeState = () => {
      const activateMsg = mensaje.value.activo === "1" ? "desactivará" : "activará";
      const actionMsg = mensaje.value.activo === "1" ? "desactivado" : "activado";

      Modal.confirm({
        title: () => "¿Estás seguro de cambiar el estado?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se ${activateMsg} el contacto ${mensaje.value.denominacion}`,
        okText: () => "Sí, cambiar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          const valor = mensaje.value.activo === "1" ? "0" : "1";
          MensajesApi.CambiarEstadoOne(toRaw(mensaje.value.idmensaje), valor)
            .then((res) => {
              fetchMensajes(currentPage.value);
              clearSelectedArrays();

              notification.success({
                message: "Operación exitosa",
                description: `Se ha ${actionMsg} el mensaje satistactoriamente.`
              });
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const handleEdit = () => {
      showModal();
    };

    const handleDelete = () => {
      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se eliminará el mensaje '${mensaje.value.denominacion}'`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          MensajesApi.deleteOne(toRaw(mensaje.value.idmensaje))
            .then((res) => {
              fetchMensajes(currentPage.value);
              clearSelectedArrays();
            })
            .catch((err) => console.log(err));
        }
      });
    };

    // const handleSMS = () => {
    //   const [iddestinatario] = state.selectedRowKeys;

    //   destinatario.value = data.value.find((u) => u.iddestinatario === iddestinatario);

    //   SMSApi.sendSMS(toRaw(destinatario.value).mensaje)
    //     .then((response) => {
    //       console.log(response);
    //     })
    //     .catch((err) => console.log(err));
    // };

    // -------- trae datos al cambiar de pagina --------
    watch(currentPage, () => {
      fetchMensajes(currentPage.value);
    });

    watch(state, () => {
      isDisabled.value = !state.selectedRowKeys.length;
    });

    // const reloadFetch = () => {
    //   fetchMensajes(currentPage.value);
    // };

    // const routes = [
    //   {
    //     path: "menu",
    //     breadcrumbName: "Vigilancia"
    //   },
    //   {
    //     path: "/destinatarios",
    //     breadcrumbName: "Destinatarios de envíos SMS"
    //   }
    // ];

    return {
      // goBack,
      // routes,
      columns,
      data,
      rowSelection,
      mensaje,
      isModalVisible,
      isLoading,
      customRow,
      closeModal,
      handleDelete,
      fetchMensajes,
      handleEdit,
      showModal,
      handleAdd,
      totalDocs,
      handleChangeState,
      currentPage,
      pageSize,
      // reloadFetch,
      isDisabled,
      clearSelectedArrays
    };
  }
};
</script>

<style></style>
